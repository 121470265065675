import { AdminPanelActionTypes, AdminPanelState } from 'modules/AdminPanel/AdminPanel.interfaces';
import { AuthActionTypes, AuthState } from 'modules/Auth/Auth.interfaces';
import { EventsActionTypes, EventsState } from 'modules/Events/Event.interfaces';
import { PricingActionTypes, PricingState } from 'modules/Pricing/Pricing.interfaces';
import { ProfileActionTypes, ProfileState } from 'modules/Profile/Profile.interfaces';
import { IAPIDAEProvider, ITourinsoftProvider } from 'modules/SIT/SIT.interfaces';
import { ILanguage } from '../modules/Global/Global.interfaces';

export const SUPER_ADMIN = 'super admin';
export const ADMIN = 'admin';
export const REDACTOR = 'redactor';
export const PRODUCER = 'producer';
export const CONTRIBUTOR = 'contributor';
export const BASE_USER = 'base_user';

export const PREMIUM = 'premium';
export const ALL_INCLUSIVE = 'all_inclusive';
export const MULTI_CHANNEL = 'multi_channel';
export const DELEGATED_ZONE = 'delegated_zone';

export type IRole = 'super admin' | 'admin' | 'redactor' | 'producer' | 'contributor' | 'base_user';

export type IStatus =
  | 'CREATED'
  | 'ON_HOLD'
  | 'IN_PRODUCTION'
  | 'PRODUCED'
  | 'ONLINE'
  | 'OFFLINE'
  | 'REJECTED'
  | 'REJECTED_PRODUCTION';

export type IState = 'Full' | 'Cancelled' | 'Postponed' | undefined;

export type IPhoneticType = 'public' | 'private' | 'other';

export type IRecordingMethod = 'ai_generated' | 'record' | 'upload' | 'order' | undefined;

export type IPlanType = 'premium' | 'all_inclusive' | 'multi_channel' | 'free' | 'delegated_zone' | string;

export interface IEvent {
  transcription?: string;
  transcription_srt?: string;
  id: number;
  main_event_id: number;
  title: string;
  slug: string;
  status: IStatus;
  description: string;
  start_date: string;
  end_date: string;
  tag: number[];
  zone: number[];
  country: string;
  zipcode: string;
  city: string;
  address: string;
  location: {
    latitude: number;
    longitude: number;
  };
  account: IEntity;
  image: File;
  reject_message: string | null;
  photo_url: string | null;
  audio_url: string | null;
  comments: IComment[];
  verify_submit_status: boolean;
  producer_account: number;
  producer_name: string | '';
  reductor_account: number;
  reports: string[];
  account_id?: number;
  audio_file?: File;
  production_required: boolean;
  photo_credit: string;
  sit_data: {
    provider: string;
    to_produce?: boolean;
  };
  anto_audio_url?: string;
  video_url_portrait?: string;
  video_url_landscape?: string;
  organizer_email?: string;
  organizer_name?: string;
  hide_home?: boolean;
  state: IState;
  organizer?: {
    screen_name: string;
    id: number;
    plan: IPlanType;
  };
  sharers?: {
    alias: string;
    screen_name: string;
    id: number;
    plan: IPlanType;
  }[];
  booking_url?: string;
}

export const optionalFormFields = ['use_contributor_credits'];

export interface IDetailedEvent {
  id: number | null;
  location: {
    latitude: number;
    longitude: number;
  };
  reports: string[];
  comments: IComment[];
  photo_url: string | null;
  image?: File;
  account: IEntity;
  start_date: string;
  end_date: string;
  event_dates: { id: number; start_date: string; end_date: string }[];
  tag: number[];
  city: string;
  address: string;
  country: string;
  zipcode: string;
  sit_data: {
    provider: string;
    to_produce?: boolean;
  };
  state: IState;
  booking_url?: string;
  organizer_name?: string;
  organizer_email?: string;
  photo_credit: string;
  details: IEventDetails[];
  account_id?: number;
  creator_id?: number;
  hide_home?: boolean;
  public_types?: number[];
  accessibility_tags: IAccessibilityTag[];
  prices?: IEventPrice[];
  website?: string;
}

export interface IAccessibilityTagsChoices {
  label: string;
  children: IAccessibilityTag[];
}

export interface IAccessibilityTag {
  id: number;
  name: string;
  short_name: string;
}

export interface IEventPrice {
  language_code: string;
  price_items: IEventPriceItem[];
}

export interface IEventPriceItem {
  index: number;
  value: number;
  comment: string;
}

export interface IEventDetails {
  id: number;
  title: string;
  status: IStatus;
  production_required: boolean;
  slug: string;
  description: string;
  producer_name: string | '';
  producer_account: number;
  language_code: string;
  video_url_portrait: string;
  video_url_landscape: string;
  audio_url: string;
  audio_file?: Blob;
  reject_message: string | null;
  use_contributor_credits: boolean;
  transcription: string;
  online_date: string;
}

export interface IPermissions {
  superAdmin: boolean;
  redactor: boolean;
  producer: boolean;
  baseUser: boolean;
  admin: boolean;
  contributor: boolean;
}

export interface ITag {
  id: number;
  name: string;
  event_count: number;
}

export interface ICardHistory {
  data: string;
  description: string;
  id: number;
  price: number;
  number_of_credits: number;
  duration?: string;
  date_to_pay: number;
  tax_amount: number;
  total_price: number;
  user: {
    address: IAddress;
    id: number;
    phone: string;
    screen_name: string;
    name: string;
    email: string;
    company_name: string | null;
  }[];
}

export interface IUser {
  upcoming_events_count: number;
  id: number;
  email: string;
  old_password: string;
  confirm_password: string;
  events: number[];
  created_at: string;
  updated_at: string;
  screen_name: string;
  password: string;
  is_active: boolean;
  favorites?: number[];
  role: IRole;
  language: ILanguage;
  entity: IEntity;
}

export interface IWidgetStyles {
  primary_color: string;
  secondary_color: string;
}

export interface IEntity {
  widget_styles: IWidgetStyles;
  id: number;
  created_at: string;
  updated_at: string;
  screen_name: string;
  avatar: string | null;
  background: string | null;
  description: string;
  zone: number[];
  extra_count_of_credits: number;
  count_of_credits: number;
  stripe_customer_id: string;
  payment_subscription_stripe_id: string;
  plan: {
    id: number;
    type: IPlanType;
  };
  payment_history: (IPlan | IPack)[];
  phone: string;
  address: {
    city: string;
    country: string;
    zipcode: string;
    location: {
      latitude: number;
      longitude: number;
    };
  };
  credits_expiration_date: string;
  social_media_accounts: {
    twitter: string;
    instagram: string;
    facebook: string;
    youtube: string;
    pinterest: string;
    tiktok: string;
    anto: string;
  };
  anto_managed_account: boolean;
  website_url?: string;
  booking_url?: string;
  additional_info?: string;
  accepted_terms_of_payment: boolean;
  sit_data: IAPIDAEProvider | ITourinsoftProvider;
  company_name: string;
  audio_url: string;
  audio: File;
  transcription: string;
  transcription_srt: string;
  alias: string;
  is_active: boolean;
  allowed_domains: string[];
  client_id: string;
  favored_count: number;
  auto_publish: boolean;
  secret_key: string;
  default_language: ILanguage;
  languages: ILanguage[];
}

export interface IZone {
  id: number;
  cities: string[];
  events_set: IEvent[];
  departments: number[];
  zipcodes: number[];
  zone_name: string;
  zone_number: number;
}

export interface IComment {
  id: number;
  author: {
    avatar: string | null;
    screen_name: string | undefined;
    title: string | undefined;
  };
  comment_text: string;
  created_at: string;
  event: number;
  record_url: string | null;
}

export interface IFilterEventsParams {
  ordering?: string;
  tag?: string;
  start_date_after?: string;
  start_date_before?: string;
  latitude?: number;
  longitude?: number;
  radius?: number;
  ids?: string;
  page?: number;
  status?: string;
  search?: string;
  current_time?: boolean;
  producer_assigned?: number;
  zones?: string;
  after_date?: string;
  category?: string;
  region?: string;
  city?: string;
  hide_home?: boolean;
}

export interface IFilterUsers {
  id?: number;
  ordering?: string;
  role?: IRole;
  search?: string;
  page?: number;
}

export interface IFilterTags {
  page?: number;
  ordering?: string;
}

export interface IPlan {
  deleted: boolean;
  description: string[];
  duration: string;
  id: number;
  name: string;
  price: number;
  stripe_id: string;
  number_credits?: number;
  offered_credits: number;
  unsubscribed?: boolean;
  date_to_pay?: number;
  credits_expiration_date?: Date;
  initial_price?: number;
  free?: boolean;
  front_display: boolean;
  canceled?: boolean;
}

export interface IPack {
  deleted: boolean;
  description: string[];
  id: number;
  name: string;
  number_credits: number;
  price: number;
  price_stripe_id: string;
  product_stripe_id: string;
  initial_price?: number;
  duration?: string;
}

export interface IAddress {
  city: string;
  country: string;
  location: { latitude: number; longitude: number };
  street_address: string;
  zipcode: string;
}

export interface IMenuList {
  icon?: React.ReactNode;
  key: string;
  exact?: boolean;
  to: string | { pathname: string };
  title: string | React.ReactNode;
  permissions?: IRole[];
  onClick?: () => void;
  className?: string;
}

export type AppStates = AdminPanelState | ProfileState | EventsState | AuthState | PricingState;
export type AppActions =
  | AdminPanelActionTypes
  | ProfileActionTypes
  | EventsActionTypes
  | AuthActionTypes
  | PricingActionTypes;
